import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Home',
    meta: { tx: 1 },
    component: () => import('@/views/index'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: { tx: 1 },
    component: () => import('@/views/login'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: { tx: 1 },
    component: () => import('@/views/agreement'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: { tx: 2 },
    component: () => import('@/views/register'),
  },
  {
    path: '/product',
    name: 'Product',
    meta: { tx: 9 },
    component: () => import('@/views/product'),
  },
  {
    path: '/with',
    name: 'With',
    meta: { tx: 3 },
    component: () => import('@/views/with'),
  },
  {
    path: '/team',
    name: 'Team',
    meta: { tx: 3 },
    component: () => import('@/views/team'),
  },
  {
    path: '/share',
    name: 'Share',
    meta: { tx: 3 },
    component: () => import('@/views/share'),
  },
  {
    path: '/with-service',
    name: 'WithService',
    meta: { tx: 3 },
    component: () => import('@/views/with-service'),
  },
  {
    path: '/authentication',
    name: 'Authentication',
    meta: { tx: 2 },
    component: () => import('@/views/authentication'),
  },
  {
    path: '/recharge-service',
    name: 'RechargeService',
    meta: { tx: 2 },
    component: () => import('@/views/recharge-service'),
  },
  {
    path: '/recharge',
    name: 'Recharge',
    meta: { tx: 3 },
    component: () => import('@/views/recharge'),
  },

  {
    path: '/profit',
    name: 'Profit',
    meta: { tx: 3 },
    component: () => import('@/views/profit'),
  },
  {
    path: '/new',
    name: 'New',
    meta: { tx: 2 },
    component: () => import('@/views/new'),
  },
  {
    path: '/me',
    name: 'Me',
    meta: { tx: 1 },
    component: () => import('@/views/me'),
  },
  {
    path: '/access-log',
    name: 'AccessLog',
    meta: { tx: 2 },
    component: () => import('@/views/access-log'),
  },
  {
    path: '/amount-change',
    name: 'AmountChange',
    meta: { tx: 2 },
    component: () => import('@/views/amount-change'),
  },
  {
    path: '/history-order',
    name: 'HistoryOrder',
    meta: { tx: 2 },
    component: () => import('@/views/history-order'),
  },
  {
    path: '/bank',
    name: 'Bank',
    meta: { tx: 3 },
    component: () => import('@/views/bank'),
  },
  {
    path: '/swift-code',
    name: 'SWIFTCode',
    meta: { tx: 3 },
    component: () => import('@/views/swift-code'),
  },
  {
    path: '/edit-bank',
    name: 'EditBank',
    meta: { tx: 2 },
    component: () => import('@/views/edit-bank'),
  },
  {
    path: '/edit-password',
    name: 'EditPassword',
    meta: { tx: 2 },
    component: () => import('@/views/edit-password'),
  },
  {
    path: '/edit-with-password',
    name: 'EditWithPassword',
    meta: { tx: 2 },
    component: () => import('@/views/edit-with-password'),
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({ routes, scrollBehavior: () => ({ y: 0 }) })

export default router
