module.exports = {
    index: {
        grid: [{
                num: '12',
                text: '全球办事处',
            },
            {
                num: '100+',
                text: '交易产品',
            },
            {
                num: '4',
                text: '权威监管',
            },
            {
                num: '450+',
                text: '专业团队',
            },
        ],
        product: [{
                title: '投资产品',
                desc: 'CompanyName 为投资者提供包括外汇、贵金属、原油、股票、指数在内的超过一百种差价合约交易产品；所有产品都可在同一个平台进行交易，带给投资者面向全球市场最好的投资机会。',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: '资金安全',
                desc: '客户的资金与公司运营资金隔离存放，资金存在不同的银行账户中,并接受监管机构对投资者的资金保护。',
                imgUrl: require('@/assets/icon_lock.svg'),
            },
            {
                title: '全球服务',
                desc: 'CompanyName 在全球拥有450+专业的员工团队，其员工拥有超过十年的金融行业经验，为所有客户提供专业的本地化服务。',
                imgUrl: require('@/assets/icon_community.svg'),
            },
        ],
        experience: {
            title: '最佳交易体验',
            desc: '我们提供全球知名度最高、最获广泛采用的在线交易平台之一MetaTrader4（MT4）。MT4交易平台提供强大的图表分析工具：五十多种技术指标和盘面分析工具。安全可靠、易于使用而又具备高阶交易者使用的功能特点，成为在线交易的标准平台。 ',
            btn: '用MT4进行交易',
        },
        product2: [{
                title: '差价合约',
                desc: '提供超过40种货币对，包括主货币对、次要货币对及其他货币对',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: '贵金属',
                desc: '金银交易是极具吸引力的替代性投资，市场流动性较大',
                imgUrl: require('@/assets/icon_gold.svg'),
            },
            {
                title: '能源',
                desc: '提供3种广受欢迎的交易商品：英国布伦特原油、美国WTI轻质原油和美国天然气',
                imgUrl: require('@/assets/icon_oil.svg'),
            },
            {
                title: '指数',
                desc: '提供十几种指数，包括多国股市指数及美元指数 ',
                imgUrl: require('@/assets/icon_indices-1.svg'),
            },
            {
                title: '股票',
                desc: '超过70支在美国及欧洲上市的知名企业股票',
                imgUrl: require('@/assets/icon_shares-1.svg'),
            },
        ],
        area: {
            title: '全球4大洲办事处',
            desc: '横跨欧洲、南美洲、亚洲及非洲',
        },
        honor: {
            title: '荣誉奖项',
            list: [{
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳机构业务经纪商',
                    desc: '2020金融科技奖',
                    desc2: '国际财富与金融大奖',
                },
                {
                    imgUrl: require('@/assets/Best-Affiliate-Program.png'),
                    title: '最佳联盟计划',
                    desc: '2020差价和约经纪商大奖',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-News-Analysis-Provider.png'),
                    title: '最佳新闻及市场分析提供者',
                    desc: '2020差价和约经纪商大奖',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-Online-Trading-Services.png'),
                    title: '最佳线上交易服务奖',
                    desc: '2020ADVFN国际金融奖',
                    desc2: 'ADVFN',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳差价合约经纪商',
                    desc: 'Rankia.com',
                    desc2: '',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最具交易透明度经纪商',
                    desc: '2019世界差价合约大奖',
                    desc2: '世界差价合约大奖',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳教育科程',
                    desc: '2019世界差价合约大奖',
                    desc2: '世界差价合约大奖',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最值得信赖的交易经纪商',
                    desc: '2019阿联酋商业奖',
                    desc2: '中东市场杂志',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: '最佳差价合约客户服务奖',
                    desc: '2019世界金融差价合约奖',
                    desc2: 'World Finance',
                },
            ],
        },
        sysPay: {
            title: '全球知名支付系统',
            desc: '高效率及自动化流程、严格的审核以确保客户的投资安全、多元化的处境渠道',
        },
        transaction: {
            title: '本公司致力于满足您的差价合约和外汇交易需求',
            desc: ' 所有交易均涉及风险。您的损失可能大于您的入金。',
            desca: '风险政策',
            text: '开始真实的外汇交易',
            btn: '开设真实账户',
        },
        brand: {
            title: ' CompanyName 是由多家公司共同使用的业务品牌，包括： ',
            text1: ' CompanyName 是位于圣文森特和格林纳丁斯的有限责任公司，公司编号为 333 LLC 2020。注册地址是：1st Floor, First St. Vincent Bank Bldg, James Street, Kingstown, St. Vincent and the Grenadines。 ',
            text2: ' CompanyName . 获英国金融行为监管局 (FCA) 授权并受其监管，FCA 许可证号码为 760555。注册地址是：1st Floor, 32 Cornhill, London EC3V 3SG, United Kingdom。 ',
            text3: ' CompanyName . 获塞浦路斯证券交易委员会 (CySEC) 授权并受其监管，许可证号码为 285/15。注册地址是：159 Leontiou A’ Street, Maryvonne Building Office 204, 3022, Limassol, Cyprus。 ',
            text4: ' CompanyName . 获毛里求斯共和国的金融服务委员会 (FSC) 授权并受其监管，许可证号码为 C118023331。注册地址是：Suite 207, 2nd Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebène, Republic of Mauritius。 ',
            text5: '高风险投资警告： 外汇和差价合约交易具有高度投机性和高风险性，并不适合所有投资者。您可能损失部分或全部投资资金。因此，您的投资金额应该在您可承受的范围之内。您应当了解保证金交易相关的所有风险。请阅读完整的',
            risk: '风险披露政策',
            text6: ' 受限制地区：CompanyName 的网站信息不针对加拿大的卑诗省、魁北克省和萨斯喀彻温省地区、日本、台湾、朝鲜民主主义人民共和国（朝鲜）、伊朗、美国的居民；并且不向身处与发布或使用该信息有违当地法律法规的国家或管辖地之人发送或供其使用。 ',
        },
        icp: {
            text1: ' © ATFX 版权所有，不得转载 ',
            text2: '琼ICP备19002799号',
            text3: '隐私政策',
            text4: 'Cookies政策',
            text5: '条款和条件',
            text6: '网站地图',
            text7: '友情链接',
        },
        bottomSwiper: [{
                text1: 'MetaTrader 4',
                text2: '全球广泛应用的交易工具、毫秒级高效执行速度、支持EA自动化交易、强大的图表分析功能、自定义添加多种指标、可设定止盈止损有效管理风险、一站式交易百余种产品、提供10万美金模拟账户充分练习、支持多种终端设备。',
            },
            {
                text1: 'CompanyName ',
                text2: 'CompanyName ——为您，有远见的交易者创建CompanyName 是目前为止最专业、最具创新的掌上交易应用程序，专业的操作界面、直观易用的管理工具、清晰的图表、细节的缩放，还有许多实用、独特的功能，将带给您流畅的交易体验。',
            },
        ],
        topSwiper: [{
                text1: '英超冠军曼城官方合作伙伴',
                text2: '携手强者共同成为行业引领',
                text3: '',
            },
            {
                text1: '央行级别顶级监管',
                text2: '七张全球监管牌照',
                text3: '客户资金隔离存放 、账户安全有保障',
            },
            {
                text1: 'WebTrader-在线交易',
                text2: '如此简单',
                text3: '专为新手交易者而创',
            },
        ],
        headTitle: 'CompanyName ',
        btnTran: '进入交易大厅',
        Welcome: '用户名',
        openAccount: '开立真实账户',
        PleaseLogin: '请登录',
        lang: '语言',
        tableBox: ['货币名称', '现价', '最低', '最高'],
        introduce1: {
            title: '丰富的交易产品',
            data: [{
                    title: '外汇',
                    imgUrl: require('@/assets/pro_1.png'),
                    text1: '60余种货币对品种',
                    text2: '高达400:1的杠杆',
                    text3: '5X24小时T+0双向交易',
                },
                {
                    title: '股指/股票',
                    imgUrl: require('@/assets/pro_2.png'),
                    text1: '20余个全球主要股指',
                    text2: '70余种全球知名股票',
                    text3: '有利于投资组合多样化',
                },
                {
                    title: '贵金属',
                    imgUrl: require('@/assets/pro_3.png'),
                    text1: '最受欢迎的避险品种',
                    text2: '杠杆高达200倍',
                    text3: '具有竞争力的点差',
                },
                {
                    title: 'ETF',
                    imgUrl: require('@/assets/pro_4.png'),
                    text1: '一篮子资产交易形式',
                    text2: '多种资产集合',
                    text3: '灵活杠杆高达20:1',
                },
            ],
        },
        introduce2: {
            title: 'CompanyName  让交易更自信',
            content: 'CompanyName 是一家接受高度监管的公司，由英国金融监管局严格监管，严格遵守欧洲的金融市场规范，每年均由独立的会计事务所稽查人员进行账目审核。CompanyName 精心为客户选择国际顶尖银行用以储备资金， 每位客户的资金都以隔离帐户存放于国际信誉评比AA级银行中，客户资金拥有绝对保障。在相关监管法规的保护下，客户资金与公司储备金完全隔离，每位客户的 资金都存放在单独的银行账户上，即使公司破产，客户资金也将受保护不被动用。',
        },
        introduce3: {
            title: '第三方交易观点',
            content: 'CompanyName 国际集团资金充足、实力雄厚，由多位从事投资及银行理财业务逾15年的金融领袖团队组成。集团旗下公司CompanyName (CompanyFullNameEnglish )设在全球顶尖金融中心伦敦，由最高级别的英国金融管理局FSA（Financial Services Authority）授权认证（FSA注册牌照号：303064），公司致力于全球华人黄金外汇市场，展开全面的黄金外汇业务，为客户提供领先优质的投资产品为目标，希望我们的投资产品是您投资理财的最佳选择。',
        },
        learnMove: '了解更多  ',
    },
    tabBar: ['交易', '收益宝', '客服', '资讯', '我的'],
    product: {
        home: '首页',
        Balance: '余额',
        money: '(元)',
        Withdrawal: '取款',
        Inpour: '存款',
        AveragePrice: '均价',
        Time: '时间',
        TimeLimit: '时限',
        Min: '分',
        Rate: '收益',
        Loss: '亏损',
        Increase: '买涨',
        Decrease: '买跌',
        O: '开盘价',
        H: '最高价',
        L: '最低价',
        C: '收盘价',
        smallMin: '分钟k线',
        hour: '小时k线',
        dayMin: '日k线',
        Trend: '分时图',
        LatestDeal: '最新成交',
        RecentTransaction: '最近交易',
        User: '用户',
        Contract: '合约',
        Direction: '方向',
        Wager: '定金',
        Amount: '金额',
        Profit: '盈亏',
        unfinished: '未完成',
        goLogin: '请登录后查看',
        ConfirmPurchase: '确认购买',
        ConfirmAllin: '全部下单',
        Buy: '购买',
        ExpectedIncome: '预期收入',
        assetType: '资产类型',
        BillingCycle: '结算周期',
        OrderDirection: '订单方向',
        MinimumBalance: '最低余额',
        MinimumPurchase: '最少购买',
        upToBuy: '最多购买',
        CurrentPrice: '当前价格',
        Confirm: '确认',
        SuccessfulPurchase: '购买成功',
        PleaseWait: '请等待',
        BuyPrice: '买入价格',
        EndPrice: '结束价格',
        settlementCycle: '结算周期',
        profit: '盈亏',
        second: '秒',
        InvestmentAmount: '投资金额',
        unlimited: '不限',
        product: '购买产品',
        InLiquidation: '平仓中',
        profitText: '盈利',
        lossText: '亏损 ',
        success: '恭喜盈利 ',
    },
    profit: {
        title: '收益宝',
        synopsis: '简介',
        TotalAmount: '总金额',
        YesterdayP: '昨日收益',
        cumulativeIncome: '累计收益',
        DailyInterest: '日利息',
        TransferIn: '转入',
        TransferOut: '转出',
        revenueRecord: '收益记录',
        SingleDay: '单日收益',
        money: '(元)',
        amount: '金额',
        placeholder: '请输入金额',
        confirm: '确认',
        success: '操作成功',
    },
    news: {
        title: '新闻资讯',
    },
    me: {
        CreditScore: '信用分',
        Inpour: '存款',
        Withdrawal: '取款',
        Team: '我的团队',
        AccessLog: '存取记录',
        FundingDetails: '资金明细',
        HistoryOrder: '历史订单',
        BankCard: '银行卡',
        RealName: '实名认证',
        ChangePassword: '修改密码',
        wlPassword: '修改取款密码',
        Share: '我要分享',
        Language: '语言切换',
        Logout: '安全退出',
        read: '已认证',
        unread: '未认证',
        close: '关闭',
        codeText: '扫码创享共赢',
        ShareText: '分享二维码',
        copyLink: '复制分享链接',
        ModifyNickname: '修改昵称',
        placeholder: '请输入昵称',
        nickNakeConfirmBtn: '确认',
        nickNakeCancelBtn: '取消',
        service: '请联系客服',
        success: '修改成功',
    },
    Team: {
        title: '我的团队',
        subordinateTitle: '的团队',
        NickName: '昵称',
        TeamNum: '下级数量',
        ChildsProfit: '提成',
        ParentProfit: '贡献提成',
        TotalProfit: '总盈利',
    },
    Inpour: {
        title: '存款',
        Bank: '网银存款',
        digitalCurrency: '数字货币',
        SelectAmount: '选择金额',
        Amount: '存款金额',
        Submit: '提 交',
    },
    rechargeService: {
        title: '网银存款',
        OpenBank: '存款银行',
        CardUserName: '持卡人',
        InpourInformation: '存款信息',
        Amount: '存款金额',
        CardNo: '银行卡号.',
        placeholder: '请联系客服获取银行卡账号',
        Support: '联系客服',
        Warm: '温馨提示：',
        line: '*先查看要入款的银行账号信息，然后通过网上银行或手机银行进行转账，转账成功后再如实提交转账信息，财务专员查收到信息后会及时添加您的款项',
        line1: '* 请尽可能选择同行办理转账，可快速到账。',
        line2: '* 存款完成后，保留单据以利核对并确保您的权益。',
        line3: '* 如出现存款失败或存款后未到账等情况，请联系在线客服获取帮助。',
    },
    Withdrawal: {
        title: '取款',
        titleInfo: '取款信息',
        unBind: '请绑定银行卡',
        unSWIFTCode: '请绑定SWIFT Code',
        AccountBalance: '账号余额',
        cashBalance: '可提取金额',
        RrozenAmount: '冻结金额',
        PRNA: '请实名认证',
        RNA: '实名认证',
        WithdrawAmount: '取款金额',
        WithdrawPassword: '取款密码',
        Submit: '确认提交',
        placeholder: '请输入取款密码',
        placeholder1: '取款收取 ',
        placeholder2: '%手续费，本次共计',
        placeholder3: '(元)',
        cardNo: '取款卡号',
        RealName: '真实姓名',
        mobile: '手机号码',
        IDNumber: '身份证号',
        CertificateAddress: '证件地址',
        CurrentAddress: '现住址',
        tips: '温馨提示',
        tipsMessage: '还未设置提现密码，马上前往设置？',
        AmountMessage: '请输入金额',
        PassMessage: '请输入正确的取款密码',
        CommissionText: '实际到账金额',
    },
    Accesslog: {
        title: '存取记录',
        Inpour: '存款',
        Withdrawal: '取款',
        Amount: '金额',
        type: '类型',
        Time: '时间',
        Status: '状态',
    },
    statusLang: {
        Review: '审核中',
        Success: '成功',
        Fail: '未通过',
    },
    FundingDetails: {
        title: '资金明细',
        Upper: '存款',
        Lower: '取款',
        Bet: '投资',
        Win: '平仓',
        BalanceToFinance: '余额转收益宝',
        FinanceeToBalance: '收益宝转余额',
        CashProfit: '下级提现收益',
        money: ' (元)',
    },
    history: {
        title: '历史订单',
        Contract: '合约',
        Amount: '金额',
        Time: '时间',
        Details: '详情',
        Profit: '盈亏',
        money: ' (元)',
        unfinished: '未完成',
    },
    bank: {
        title: '银行卡',
        editTitle: '绑定银行卡',
        accountName: '开户人姓名',
        opening: '开户银行',
        point: '开户网点',
        card: '银行卡号',
        DigitalCurrency: '数字货币',
        usdt: 'USDT地址（非必填）',
        btc: 'BTC地址（非必填）',
        eth: 'ETH地址（非必填）',
        placeholder1: '请输入开户人姓名',
        placeholder2: '请选择开户银行',
        placeholder3: '请输入开户网点名',
        placeholder4: '请输入银行卡号',
        submit: '确定',
        info: '注：如需修改，请联系客服',
    },
    SWIFTCode: {
        CardUserName: '收款人姓名',
        Area: '收款人所在地',
        DetailArea: '收款人地址',
        OpenBank: '银行所在地',
        CardNo: '收款账号',
        usdt: '请输入SWIFT Code号',
        btc: '请输入收款账号',
        eth: '请输入',
        placeholder1: '请输入收款人姓名',
        placeholder2: '请输入收款人所在地',
        placeholder3: '请输入收款人地址',
        placeholder4: '请输入银行所在地',
        submit: '确 定',
        update: '修 改',
        info: '注：如需修改，请联系客服',
    },
    withPass: {
        title: '取款密码',
        OldPasswordle: '旧密码',
        NewPassword: '新密码',
        ConfirmPassword: '确认密码',
        placeholder: '请输入旧密码',
        placeholder1: '请输入新密码',
        placeholder2: '确认新密码',
        confirm: '确认修改',
    },
    authentication: {
        title: '实名认证',
        RealName: '真实姓名',
        Mobile: '手机号码',
        IDNumber: '身份证号',
        CertificateAddress: '证件地址',
        CurrentAddress: '现住址',
        CertificateZ: '证件正面照片',
        CertificateF: '证件反面照片',
        warnInfo: '* 实名信息只能填写一次，请如实填写',
        Confirm: '确认提交',
        placeholder: '请输入真实姓名',
        placeholder1: '请输入手机号码',
        placeholder2: '请输入正确的身份证号',
        placeholder3: '请输入证件地址',
        placeholder4: '请输入现住址',
        message: '请填写完整的表单',
        message1: '请输入正确的手机号码',
        success: '实名成功',
    },
    login: {
        title: '登录',
        lang: '语言',
        Account: '账号',
        Password: '密码',
        btn: '登录',
        fail: '请完善表单',
        ForgotPassword: '忘记密码',
        NoAccount: '还没有账号？',
        Registration: '注册',
        OtherLogin: '其他登录',
        success: '登录成功',
        ageent: '阅读并同意',
        ageentTitle: '服务协议',
        ageentFail: '请阅读并勾选服务协议',
        renzheng: '香港网络安全局认证',
        beian: '京公网安备11010202000001号',
    },
    register: {
        title: '注册',
        lang: '语言',
        Account: '账号',
        PhoneNumber: '手机号',
        Password: '密码',
        ConfirmPassword: '确认密码',
        InvitationCode: '邀请码',
        btn: '注册',
        haveAccount: '已有账号？',
        Login: '登录',
        placeholder: '用户名必须字母开头，6-18位',
        placeholder1: '请输入密码',
        placeholder2: '请再次输入密码',
        placeholder3: '请输入邀请码',
        placeholder4: '请输入正确的手机号',
        success: '注册成功',
        message: '密码由字母数字和下划线组成，且大于等于6个字符',
        message1: '请输入至少6位的确认密码',
        message2: '两次密码不一致'
    },
    public: {
        SubmittedSuccessfully: '提交成功',
        select: '请选择',
        cancel: '取消',
        confirm: '确认',
        RefreshSucceeded: '刷新成功',
        noMoreData: '没有更多数据了',
        pullingText: '下拉即可刷新 ...',
        ReleaseText: '释放即可刷新 ...',
        loadingText: '加载中 ...',
    },
    xpgyHome: {
        title: '上海心苹公益基金会',
        articleTitle: '上海心苹公益基金会2021年度第一届理事会第5&6次会议圆满召开',
        articleContent1: '12月2日下午，上海心苹公益基金会2021年度第一届理事会第5&6次会议（以下简称“理事会会议”）在上海555大厦12楼会议室圆满召开。上海心苹公益基金会理事长赖颖生，副理事长吴伟，理事王国民、何非方出席，监事申屠媛艳、金幸福出席，周晔如、张翊、许新苹列席会议。',
        articleContent2: ' 2021年，上海心苹公益基金会在上海民政局的悉心指导及理事会的领导下，共完成4个专项项目，2个设施服务点即将开展服务，并与“上海真爱梦想基金会”达成合作进行联合劝募行动，全年工作成果得到民政局基金会管理处的高度肯定。同时稳步推进基金会官网的搭建、3A社会组织等级评定以及非营利组织免税资格认定工作，逐步完善人员配备和团队架构，并计划在2022年提升团队的综合业务能力和专业技能，积极为符合基金会公益服务宗旨的对象提供支持，进一步做好信息披露工作，做好资金预算工作，确保下一年度管理费合理使用，各项指标力争达到主管部门对基金会的要求。理事会及监事会在本次会议上听取了秘书处对2021年度基金会工作情况及2022年工作计划的汇报，并对基金会的工作成果表示肯定。',
        articleContent3: '理事会会议审议并通过了理事会、监事会的调整事宜，任命周晔如女生担任上海心苹公益基金会秘书长，张翊女士担任副秘书长;审议并通过了基金会章程的修改，讨论并审议了基金会各项规章制度。',
        articleContent4: '会议上理事们纷纷献计献策，提出心苹基金会要吸收更多专业人员，做好项目本地化，深耕社区，在上海打好扎实的项目基础，不仅要发挥线上筹款的长处，同时也要挖掘更多的线下企业进行捐款。',
        articleContent5: '最后，理事长赖颖生表示，在接下来的工作中，自己将与更多的企业资源对接，通过劝募资金增加基金会的项目空间，另一方面加强员工团建，安排好团队能力培训和氛围建设，稳固团队凝聚力，希望大家拧成一股绳，把基金会工作做好，进一步在上海活跃慈善氛围，增进行业意识，充分发挥资源与平台的优势，共同保障基金会自身的高质量发展。',
        footer1: '上海心苹公益基金会',
        footer2: '沪ICP备2021034925号',
        footer3: '沪公网安备 31010602006588号',
        footer4: '技术支持: 杭州映派科技有限公司',
    },
}