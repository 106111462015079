import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    lang: localStorage.getItem('language') || 'zh',
    basicConfig: {},
    connection: null,
    productList: [],
    newitemprice: '',
    productId: null
  },
  mutations: {
    SET_LANG: (state, lang) => {
      state.lang = lang
    },
    SET_BASIC: (state, basic) => {
      state.basicConfig = basic
    },
    SET_CONNECTION: (state, connection) => {
      state.connection = connection
    },
    SET_NEWITEMPRICE: (state, newitemprice) => {
      state.newitemprice = newitemprice
    },
    SET_PRODUCTID: (state, productId) => {
      state.productId = productId
    },

    SET_PRODUCTLIST: (state, productList) => {
      state.productList = productList
    },
  },
  getters: {
    lang: (state) => state.lang,
    basicConfig: (state) => state.basicConfig,
    connection: (state) => state.connection,
    newitemprice: (state)=> state.newitemprice,
    productId: (state) => state.productId,
    productList: (state) => state.productList,
  },
  actions: {},
  modules: {},
})
